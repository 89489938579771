import {Link, Trans, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import './header.css';
import React, { useState } from 'react';
import UkFlag from '../svg/flagpack_gb-ukm.svg';
import HkFlag from '../svg/hkFlag.svg';

const Header = () => {
  const {languages, originalPath, t} = useI18next();
  const context = React.useContext(I18nextContext);
  let [engActive] = useState(context.language == 'en' ? true : false);
  return (
    <header className="header-container">
      <div className="main-header">
        <h2>
          <Link to="/">
            {t('siteTitle')}
          </Link>
        </h2>
        <ul className="languages">
          <li>
            <Link to={originalPath} language="en" className={engActive ? 'hidden' : ''}>
              English
              <UkFlag className="flag"></UkFlag>
            </Link>
          </li>
          <li>
            <Link to={originalPath} language="zh" className={engActive ? '' : 'hidden'}>
              中文
              <HkFlag className="flag"></HkFlag>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
