
import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';
import { Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Header from './header';
import './layout.css';

const Layout = ({children}) => {
  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()} <Trans>New Hope Chiropractic</Trans>. <Trans>All Rights Reserved</Trans>.
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
